import './App.css';
import Navbar from './Component/Navbar/Navbar';
import Home from './Pages/Home/Home';
import Footer from './Component/Footer/Footer';
import About from  './Pages/About/About'
import Gallery from './Pages/Gallery/Gallery';
import ContactUs from './Pages/Contact/ContactUs';
import OurMenus from './Pages/Menus/OurMenus'
import MenuItem from './Pages/MenuItem/MenuItem';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/gallery" element={<Gallery
         accessToken="IGQWROaEdMTW1CbHQybVBwYlk1eE5TWHRRQngwZAWNDa2ZAUWTFuWTMzb213NWR0WFZANcEJwWnI2d1ZArYjliazRyQlgxd3ZAES3hWZAlZAvbWxlRkswVk5ldzdhZAHFrcUs4MzVRazF3NW5GZAUtMVVhGbkFuOHlxRHlvaE0ZD"
         limit={21}
        />} />
        <Route path="/contact" element={<ContactUs />} /> 
        <Route path="/our-menus" element={<OurMenus />} />
        <Route path="/menu/*" element={<OurMenus />} />
        <Route path="/menu-item/:id" element={<MenuItem />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;