import React from 'react';
import { useParams, Link } from 'react-router-dom';
import './MenuItem.css';
import {Beverages,Staters, wazwan, indian, veg } from '../MenuData';

const MenuItem = () => {
    const { id } = useParams();
    const allItems = [...Beverages,...Staters,...wazwan,...indian,...veg];
    const item = allItems.find(item => item.id === parseInt(id));


    if (!item) {
        return <div>Item not found</div>;
    }

    return (
        <div className="menu-item-page">
            <div className="item-details">
                <h1 className="item-name">{item.name}</h1>
                <div className="item-image">
                    <img src={item.image} alt={item.name} />
                </div>
                <p className="item-description">{item.description}</p>
                <div className="button-container">
                    <Link to="/our-menus" className="button">Back to Menu</Link>
                    <Link to="/contact" className="button contact">Contact Us</Link>
                </div>
            </div>


            <div className="online-order">
                <h2>Online Order</h2>
                <div className="order-options">
                    <img src="../images/zomato.webp" alt="Jahez" />
                    <img src="../images/swiggy.webp" alt="Hunger Station" />
                </div>
            </div>
        </div>
    );
};

export default MenuItem;