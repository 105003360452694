export const Beverages = [
    {
        name: 'Namkeen Tea',
        description: 'A flavourful twist on traditional tea, infused with savory spices and herbs, providing a delightful and refershing taste experience.',
        id: 101,
        image: "../images/beverages/namkeentea.jpeg",
    },
    {
        name: 'Kashmiri Kahwa',
        description: 'An authentic kashmiri drink infused with spices native to the region, offering a unique blend of warmth and flavour.',
        id: 102,
        image: "../images/beverages/kashmirikahwa3.jpg", 
    },
    {
        name: 'Tea',
        description: 'A classic hot beverage brewed from steeped tea leaves, offfering diverse flavours and a commforting warmth for any occasion.',
        id: 103,
        image: "../images/beverages/tea.jpeg"
    },
    {
        name: 'Cold drink',
        description: 'Fizzy fun, clasic flavours to quench your thirst and beat the heat.',
        id: 104,
        image: "../images/beverages/cold-drink.jpg",
    },
    {
        name: 'Lassi',
        description: 'Blended yougurt with water, sugar, creating a refreshing tangy and sweet drink with a creamy texture.',
        id: 105,
        image: "../images/beverages/lassi.webp",
    },
];

export const Staters = [
    {
        name: 'Kabab',
        description: 'Authentic spiced mutton, kebabified to juicy glory.',
        id: 201,
        image: "../images/staters/kabab.jpeg",
    },
    {
        name: 'Chilli Chicken',
        description: 'Chispy chicken tossed in a fiery blend of spices and peppers, delivering a perfect balance of heat and flavour',
        id: 202,
        image: "../images/Staters/chilli-chicken.webp", 
    },
    {
        name: 'Garlic Chicken',
        description: 'Boneless chicken cooked with aromatic garlic, lending a rich, savory flavour with a hint of sweetness.',
        id: 203,
        image: "../images/staters/garlic-chicken.webp", 
    },
    {
        name: 'Galouti Kabab',
        description: 'Legendary mutton kebabs from Lucknow, marinated in a delicate blend of spices and herbs. Incredibly tender and rich, a true taste of Nawabi cuisine.'
        ,
        id: 204,
        image: "../images/staters/galouti-kabab.jpg"
    },
    {
        name: 'Malai Tikka',
        description: 'Velvety Malai-coated chicken, grilled to succulent perfection.',
        id: 205,
        image: "../images/staters/ChickenMalaiTikka.webp"
    },
    {
        name: 'Kabab Kanti',
        description: 'Boldly spiced mutton kebabs, perfect for the adventurous palate.',
        id: 205,
        image: "../images/staters/kabab knti.jpeg"
    },
];



export const wazwan = [
    {
        name: 'Goshtab',
        description: 'Juicy mutton meatballs in a velvety yougurt & almond gravy',
        id: 301,
        image: "../images/main/goshtab.jpeg"
    },
    {
        name: 'Methi Maaz',
        description: 'Flavorful minced mutton cooked in aromatic fenugreek sauce, seasoned with kashmiri spices.',
        id:  302,
        image: "../images/main/methi-maaz.jpeg"
    },
    {
        name: 'Tabak Maaz',
        description: "Crunchy mutton ribs, golden-fried & juicy, bursting with warm spice & smoky charm" ,
        id:  303,
        image: "../images/main/tabak-maaz.png"
    },
    {
        name: 'Aab Gosh',
        description: 'Silken milk & warm spices sing on your tongue',
        id:  304,
        image: "../images/main/aab-gosh.jpeg"
    },
    {
        name: 'Waza Chicken',
        description: 'Tantalizing waza Chicekn: grilled to perfection, an exquisite treat.',
        id:  305,
        image: "../images/main/waza-chi.webp"
    },
    {
        name: 'Rogan Josh',
        description: "Royal feast: Experience Kashmir's soul slow cooked mutton & aromaric warmth.",
        id:  306,
        image: "../images/main/rogan-josh.webp"
    },
    {
        name: 'Rista ',
        description: "Kashmir's fiery crown jewel: Juicy mutton meatballs in a vibrant saffron-tomato dance,  bursting with kashmiri spice.",
        id:  307,
        image: "../images/main/rista-kashmiri.webp"
    },
    {
        name: 'Lahabi Kabab',
        description: 'Soft & succulent, flavourful kebabs in minty yougurt, a kashmiri classic.',
        id:  308,
        image: "../images/main/lehabi.webp"
    },
    {
        name: 'Mirchi Korma',
        description: 'Kashmiri heatwave! Fiery chillies meet succulent lamb in a rich korma embranch.',
        id:  309,
        image: "../images/main/mirchi-korma.webp"
    },
];


export const indian = [
    {
        name: 'Tandoori Chicken',
        description: 'Taste the explosion of tandoori flavour as juicy chicken, marinated with spices, meets the zing of fresh lemon',
        id: 401,
        image: "../images/indian/tandoori-chicken.jpeg",
    },
    {
        name: 'Karahi Chicken',
        description: "Stir-fried chicken cooked with bell peppers onions, andspces in a traditional indian kadhi. Spicy, vibrant and classic. ",
        id: 402,
        image: "../images/indian/karahi-chicken.jpg"
    },
    {
        name: 'Butter Chicken',
        description: 'Tender chicken bathed in a creamy tomato gravy, kissed with butter and aromatic spices, rich, valvety and heavenly',
        id: 403,
        image: "../images/indian/butterchicken.jpg", 
    },
    {
        name: 'Chicekn Do pyaza',
        description: 'Cooked with an abundance of onions, tomatos, and aromatic spices. Sweet, tangy, full of flavour',
        id: 404,
        image: "../images/indian/chicken-do-pyaza.jpg", 
    },
    {
        name: 'Chicken Rara',
        description: 'Prepared in a rich, spicy gravy with minced meat packed with layers of flavours.',
        id: 405,
        image: "../images/indian/chicken-rara.webp", 
    },
    {
        name: 'Masala Chicken',
        description: 'Just cut meat marinated in a fragrant spice blend, grilled to perfection. Bold, aromatic and bursting with flavour',
        id: 406,
        image: "../images/indian/masala-chicken.jpeg", 
    },
];




export const veg = [
    {
        name: 'Veg Biryani',
        description: 'Rice layered with a colorful mosaic of seasonal vegatables, infused with exotic spices and caramelized onions.',
        id: 501,
        image: "../images/veg/veg_biryani.jpeg"
    },
    {
        name: 'Matar Paneer',
        description: 'Soft Paneer cubes mingled ith vibrant green peas in a tomato-based gravy, a harmonious blend of textures and flavours.',
        id:  502,
        image: "../images/veg/matar-paneer.jpg"
    },
    {
        name: 'Dal Makhni',
        description: 'Creamy black lentils and kidney beans simmered with spices and finished with cream. Rich, creamy and irresistibly flavourful.',
        id:  503,
        image: "../images/veg/dal-makhani.webp"
    },
    {
        name: 'Shahi Paneer',
        description: 'Paneer simmered in a luxurious gravy of cashews, cream, aromatic spices. Regal, indulgent and utterly delicious.',
        id:  504,
        image: "../images/veg/shahi_panner.webp"
    },
];
