import React, { useEffect, useState } from 'react';
import './Gallery.css';

const Gallery = ({ accessToken, limit = 21 }) => {

  const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInstagramPosts = async () => {
            if (!accessToken) {
                setError('Access token is required');
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const response = await fetch(
                    `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&limit=${limit}&access_token=${accessToken}`
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setPosts(data.data);
                setError(null);
            } catch (error) {
                setError('Failed to fetch Instagram posts. Please try again later.');
                console.error('Error fetching Instagram posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInstagramPosts();
    }, [accessToken, limit]);

    if (loading) {
        return (
            <div className="instagram-loader">
                <div className="loader-spinner"></div>
                <p>Loading posts...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="instagram-error">
                <span className="error-icon">⚠️</span>
                <p>{error}</p>
            </div>
        );
    }

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };


  return (
    <div className="instagram-feed-container">
    <header className="instagram-header">
        <h1>Instagram Feed</h1>
        <p>{posts.length} Posts</p>
    </header>
    
    <div className="instagram-grid">
        {posts.map(post => (
            <article key={post.id} className="instagram-post">
                <div className="post-content">
                    <a 
                        href={post.permalink} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="post-link"
                    >
                        {post.media_type === 'VIDEO' ? (
                            <div className="video-container">
                                <video 
                                    poster={post.thumbnail_url}
                                    className="post-media"
                                >
                                    <source src={post.media_url} type="video/mp4" />
                                    Your browser does not support videos.
                                </video>
                                <span className="video-icon">▶</span>
                            </div>
                        ) : (
                            <img 
                                src={post.media_url} 
                                alt={post.caption || 'Instagram post'}
                                className="post-media"
                                loading="lazy"
                            />
                        )}
                        
                        <div className="post-overlay">
                            <div className="post-details">
                                {post.caption && (
                                    <p className="post-caption">
                                        {post.caption.length > 100 
                                            ? `${post.caption.substring(0, 100)}...` 
                                            : post.caption}
                                    </p>
                                )}
                                <span className="post-date">
                                    {formatDate(post.timestamp)}
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
            </article>
        ))}
    </div>
</div>
  )
}

export default Gallery
