import React,{useState,useEffect,useCallback} from 'react'
import './About.css'
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

const popularItems = [
  { id: 1, title: "Beverages",  image: "../images/beverages.png", link: "/menu/beverages" },
  { id: 2, title: "Starters",  image: "../images/staters.jpg", link: "/menu/starters" },
  { id: 3, title: "Indian-delicacies",  image: "./images/indian_del.jpg", link: "/menu/indian-delicacies" },
  { id: 4, title: "Main Course",  image: "../images/home-section1.webp", link: "/menu/main-course" },
  { id: 5, title: "Vegetarian Specialties", image: "../images/vegetrian.jpg", link: "/menu/vegetarian-specialties" },
];

const About = ({autoSlideInterval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage =window.innerWidth < 480 ? 1 : 4; 
  const gap = 20;
  const navigate = useNavigate();


  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % popularItems.length);
  }, [popularItems.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + popularItems.length) % popularItems.length);
  }, [popularItems.length]);

  useEffect(() => {
    const handleResize = () => {
      setCurrentIndex(0); // Reset the index to first item on screen resize
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(nextSlide, autoSlideInterval);
    return () => clearInterval(interval);
  }, [nextSlide, autoSlideInterval]);

  // Create an array with additional items for seamless looping
  const extendedItems = [
    ...popularItems.slice(-itemsPerPage),
    ...popularItems,
    ...popularItems.slice(0, itemsPerPage)
  ];

  const handleLinkClick = (e, link) => {
    e.preventDefault();
    navigate(link);
};



  return (
    <>
    <div className="wazwan-container">
      <div className="wazwan-header">
        <h1>Experience the Essence of Tradition and Luxury at Wazwan Legacy</h1>
        <p>
        At Wazwan Legacy, we’re passionate about celebrating culture through cuisine. Nestled in the heart of Chandigarh, Mohali, and 3B2 Mohali, our restaurants are fully operational and ready to provide a luxurious fine dining experience that immerses you in the flavors and traditions of Kashmiri and North Indian culinary heritage.
        </p>
        <p>
        Our mission is to bring the rich, soulful dishes of Kashmir and the broader North to Punjab, blending authentic recipes with a refined, modern ambiance. Alongside our signature Wazwan dishes, we have extended our menu to include timeless Mughlai delicacies like Galouti Kabab, Paratha, Lucknowi Biryani, and Nihari. Each dish is thoughtfully prepared to evoke the essence of these culinary traditions.
        </p>
        <p>
        At Wazwan Legacy, we believe every meal is an opportunity to connect with culture and heritage. Our attention to detail—from décor to service—ensures that each visit is an experience worth savoring.
        </p>
        <div className="wazwan-buttons">
          <button className="contact-btn">Contact Us</button>
          <button className="menu-btn">View Menu</button>
        </div>
      </div>
      </div>



    <div className='online-delivery1 mt-5'>
            <div className="popular-items1">
                <h1>Our Popular <span className="highlight">Dishes</span></h1>
               

                <div className="menus-page" style={{ position: 'relative', padding: '0 40px', overflow: 'hidden' }}>
                    <div style={{
                        display: 'flex',
                        gap: `${gap}px`,
                        transition: 'transform 0.5s ease-in-out',
                        transform: `translateX(calc(-${(currentIndex + itemsPerPage) * (100 / itemsPerPage)}% - ${currentIndex * gap}px))`,
                    }}>
                        {extendedItems.map((item, index) => (
                            <Link
                                to={item.link}
                                key={`${item.id}-${index}`}
                                className="menu-item"
                                onClick={(e) => handleLinkClick(e, item.link)}
                                style={{
                                    flex: `0 0 calc(${100 / itemsPerPage}% - ${gap * (itemsPerPage - 1) / itemsPerPage}px)`
                                }}
                            >
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    style={{
                                        width: '100%',
                                        height: '300px',
                                        objectFit: 'cover'
                                    }}
                                />
                                <div className="menu-item-info">
                                    <h3>{item.title}</h3>
                                    <p>{item.subtitle}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <button onClick={prevSlide} className="nav-button prev" aria-label="Previous item">
                        <ChevronLeft size={24} />
                    </button>
                    <button onClick={nextSlide} className="nav-button next" aria-label="Next item">
                        <ChevronRight size={24} />
                    </button>
                </div>


        <div className="btn-menu-order">
          <Link to={'/our-menus'}>
          <button>View Menu</button> </Link>
        </div>
      </div>

</div>



<div className="kashmiri-wazwan mt-5">
      <div className="header">
        <h1>From Our First Outlet to New Horizons: Thank You!</h1>
       
      </div>
      <div className="description">
        <p>
        On February 2, 2024, we opened the doors to our first Wazwan Legacy outlet in Mohali, unsure of what awaited us but driven by a vision to bring authentic cultural flavors to Punjab. From that day, each of you welcomed us with open arms, and the overwhelming love and appreciation we received became the foundation of our journey.
        </p>
        <p>
        With your incredible support, we have now grown to serve you at two more locations: one in the vibrant city of Chandigarh and another in the beloved 3B2 area of Mohali. Every step forward is a testament to the warmth you have shown us, and we’re excited to continue this journey with you. Your support has made it all possible, and with you by our side, we have many more miles to go. Thank you for being part of the Wazwan Legacy family—let’s keep creating memories together!
        </p>
        
      </div>
      <div className="buttons">
        <button className="contact-us">Contact Us</button>
        <button className="view-menu">View Menu</button>
      </div>
    </div>

    
<div className='online-delivery mt-5'>
  <div className='text-place'>
    <h1>Open<font> 7 days</font></h1>
    <svg aria-hidden="true" className='svg-clock' viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
                <path d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"></path>
              </svg>
              12:00PM - 11:00PM
              <br />

<Link to={'/our-menus'}>
              <button>Get online delivery</button></Link>
              <span>or call us +91 884-7026594, +91 73888 48178</span>
  </div>
</div>

<div className='delivery-area-des mt-5'>
  <h1>delivery <font> Area</font></h1>
  <p>We are available for delivery of our Kashmiri wazwan in <b>Chandigarh and Mohali</b> region in India. For delivery in other regions, <br /> please contact us directly.</p>
</div>
</>
  )
}

export default About
